import Testing from '../components/Testing'

function TestingPage() {
  return (
    <div className="p-5">
      <Testing />
    </div>
  );
}

export default TestingPage;